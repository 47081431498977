<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses" >
    <tr>
      <slot name="columns" >
        <th>Name</th>
        <!-- <th>Read and Download?</th> -->
        <th>Action</th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in publicDocuments" :key="index.url">
      <slot :row="item">
        <td> {{item.name}}</td>
        <!-- <td>{{item.date}}</td> -->
        <!-- <td><strong>{{item.type}}</strong></td> -->
        <td><button class="btn btn-primary" @click="goToDocument(item.url, item.name, item.isDownloadeable)">{{item.action}}</button></td>
      </slot>
      <slot>
      </slot>
    </tr> 
    <tr v-for="(item, index) in privateDocuments" :key="index">
        <slot :row="item">
          <td> {{item.name}}</td>
          <!-- <td>{{item.date}}</td> -->
          <!-- <td><strong>{{item.type}}</strong></td> -->
          <td><button class="btn btn-primary" @click="goToDocument(item.url, item.name, item.isDownloadeable)">{{item.action}}</button></td>
        </slot>
        <slot>
        </slot>
      </tr> 
    </tbody>
    
  </table>
</template>
<script>
  import { mapState, mapActions } from 'vuex';
  export default {
    name: 'documents-table',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      }
    },
    computed: {
      ...mapState(['publicDocuments', 'privateDocuments']),
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      hasValue(item, column) {
        return item[column.toLowerCase()] !== "undefined";
      },
      itemValue(item, column) {
        return item[column.toLowerCase()];
      },
      ...mapActions(['getPublicDocuments', 'getPrivateDocuments']),
      goToDocument(ref, name, isDownloadeable){
          if (!isDownloadeable) {
              ref = ref + '#toolbar=0'
              
          }
          window.open(ref, name, "width=300, height=200")
      }
    },
    created(){
      this.getPublicDocuments();
      this.getPrivateDocuments();
    }
  };
</script>
<style>



</style>