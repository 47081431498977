<template>
  <div>
    <!-- CARDS -->
    <div class="row">
      <!-- NUMBERS OF SHARES -->
      <div class="col-lg-4" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h1 class="card-category">NUMBER OF COMMON SHARES</h1>
            <h1 class="card-title"><i class="tim-icons icon-single-copy-04 text-success "></i> {{profile.numberOfShares}}</h1>
          </template>
        </card>
      </div>
      <!-- % OF TOTAL STOCKS -->
      <div class="col-lg-4" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h1 class="card-category">PERCENTAGE OF TOTAL SHARES OUTSTANDING</h1>
            <h1 class="card-title"><i class="tim-icons icon-chart-bar-32 text-info "></i> {{profile.percentOfTotalShares}}%</h1>
          </template>
        </card>
      </div>
      <!-- EQUITY VALUE OF SHARES -->
      <div class="col-lg-4" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h1 class="card-category">BOOK VALUES OF SHARES - 06/30/2020</h1>
            <h1 class="card-title"><i class="tim-icons icon-money-coins text-primary "></i> {{profile.pvv}}</h1>
          </template>
        </card>
      </div>
    </div>
    <!-- DOCUMENTS -->
    <div class="row">
      <div class="col-12">
        <card class="card" :header-classes="{'text-right': isRTL}">
          <h4 slot="header" class="card-title">{{$t('dashboard.simpleTable')}}</h4>
          <div class="table-responsive">
            <DocumentsTable />
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <card type="chart">
          <template slot="header">
            <h1 class="card-category">CALENDAR MEETINGS/EVENTS</h1>
          </template>
          <!-- <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23719949&amp;ctz=America%2FArgentina%2FBuenos_Aires&amp;src=Y18zaXJndDNiOHZob21ubjFrMnVzZDJlbDJhNEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%238E24AA&amp;showTitle=0&amp;showCalendars=0&amp;showNav=1&amp;showDate=1&amp;showTabs=1&amp;showTz=0&amp;showPrint=0" style="border-width:0" width="100%" height="500" frameborder="0" scrolling="no"></iframe> -->
          <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23709949&amp;ctz=America%2FArgentina%2FCordoba&amp;src=cDFvajgyaG9zYTRmbnNzbHFiNWJyM3RrdThAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23709949&amp;showPrint=0&amp;showTabs=1&amp;showCalendars=0&amp;showTz=1&amp;showTitle=0" style="border-width:0" width="100%" height="600" frameborder="0" scrolling="no"></iframe>
        </card >
      </div>
    </div>
    <!-- CHARTS
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <card type="chart">
        <template slot="header">
          <h1 class="card-category">CALENDAR MEETINGS/EVENTS</h1>
        </template>
          <functional-calendar
            v-model="calendar"
            :is-date-range="false"
            :date-format="'dd-mm-yyyy'"
            :change-month-function="true"
            :change-year-function="true"
            :markedDates="markedDates"
            class="calendar multiple"
            ref="Calendar"
            v-slot:default="props"
          >
            <div class="text-center">
              {{ props.day.day }}
            </div>
          </functional-calendar>
        </card>
      </div>
      <div class="col-lg-6 col-md-6">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
                <h5 class="card-category">{{$t('dashboard.totalShipments')}}</h5>
                <h2 class="card-title">Stock Values of BIOX</h2>
              </div>
              <div class="col-sm-6">
                <div class="btn-group btn-group-toggle"
                     :class="isRTL ? 'float-left' : 'float-right'"
                     data-toggle="buttons">
                  <label v-for="(option, index) in bigLineChartCategories"
                         :key="option"
                         class="btn btn-sm btn-primary btn-simple"
                         :class="{active: bigLineChart.activeIndex === index}"
                         :id="index">
                    <input type="radio"
                           @click="initBigChart(index)"
                           name="options" autocomplete="off"
                           :checked="bigLineChart.activeIndex === index">
                    {{option}}
                  </label>
                </div>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%"
                        ref="bigChart"
                        chart-id="big-line-chart"
                        :chart-data="bigLineChart.chartData"
                        :gradient-colors="bigLineChart.gradientColors"
                        :gradient-stops="bigLineChart.gradientStops"
                        :extra-options="bigLineChart.extraOptions">
            </line-chart>
          </div>
        </card>
      </div>
    </div> -->
  </div>
</template>
<script>
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import * as chartConfigs from '@/components/Charts/config';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import TaskList from './Dashboard/TaskList';
  import DocumentsTable from '@/components/DocumentsTable';
  import config from '@/config';
  import { mapState, mapActions } from 'vuex'
  import VueJsonPretty from "vue-json-pretty";
  import { FunctionalCalendar } from "vue-functional-calendar";



  export default {
    components: {
      LineChart,
      BarChart,
      TaskList,
      PulseLoader,
      DocumentsTable,
      VueJsonPretty,
      FunctionalCalendar
    },
    data() {
      return {
        calendar: {},
        data: {
          clieckedToday: false
        },
        markedDates: [""],
        color: '#719949',
        height: '1000px',
        width: '1rem',
        updateComponents : 0,
        bigLineChart: {
          allData: [
            [100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100],
            [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120],
            [60, 80, 65, 130, 80, 105, 90, 130, 70, 115, 60, 130]
          ],
          activeIndex: 0,
          chartData: {
            datasets: [{ }],
            labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          },
          extraOptions: chartConfigs.purpleChartOptions,
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.4, 0],
          categories: []
        },
        purpleLineChart: {
          extraOptions: chartConfigs.purpleChartOptions,
          chartData: {
            labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
            datasets: [{
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [80, 100, 70, 80, 120, 80],
            }]
          },
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.2, 0],
        },
        greenLineChart: {
          extraOptions: chartConfigs.greenChartOptions,
          chartData: {
            labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV'],
            datasets: [{
              label: "My First dataset",
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [90, 27, 60, 12, 80],
            }]
          },
          gradientColors: ['rgba(66,134,121,0.15)', 'rgba(66,134,121,0.0)', 'rgba(66,134,121,0)'],
          gradientStops: [1, 0.4, 0],
        },
        blueBarChart: {
          extraOptions: chartConfigs.barChartOptions,
          chartData: {
            labels: ['USA', 'GER', 'AUS', 'UK', 'RO', 'BR'],
            datasets: [{
              label: "Countries",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [53, 20, 10, 80, 100, 45],
            }]
          },
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.4, 0],
        },
      }
    },
    computed: {

      ...mapState(['stockValues', 'profile', 'loader']),
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
      bigLineChartCategories() {
        return this.$t('dashboard.chartCategories');
      },
    },
    methods: {
      preMonth() {
        this.$refs.Calendar.PreMonth();
      },
      nextMonth() {
        this.$refs.Calendar.NextMonth();
      },
      preYear() {
        this.$refs.Calendar.PreYear();
      },
      nextYear() {
        this.$refs.Calendar.NextYear();
      },
      chooseDate(date) {
        this.$refs.Calendar.ChooseDate(date);
      },
      markDates() {
        let someDate = new Date();
        someDate.setDate(someDate.getDate() - 1);
        let yesterday = this.getFormatedDate(someDate);
        someDate.setDate(someDate.getDate() + 2);
        let tommorow = this.getFormatedDate(someDate);

        this.markedDates.push(yesterday, tommorow);
      },
      unMarkYesterdayDate() {
        let someDate = new Date();
        someDate.setDate(someDate.getDate() - 1);
        let yesterday = this.getFormatedDate(someDate);

        let index = this.markedDates.findIndex(date => {
          return date === yesterday;
        });
        if (index !== -1) {
          this.markedDates.splice(index, 1);
        }
        someDate = new Date();
      },
      getFormatedDate(date) {
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let y = date.getFullYear();

        return dd + "-" + mm + "-" + y;
      },
      forceUpdateComponents(){
        this.initBigChart(0)
      },
      initBigChart(index) {
        let chartData = {
          datasets: [{
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.stockValues.stock[index] || []
          }],
          labels: this.stockValues.date,
        }
        this.$refs.bigChart.updateGradients(chartData);
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;

      },
      ...mapActions(['getStockValues']),

    },
    mounted() {
      this.i18n = this.$i18n;
      if (this.enableRTL) {
        this.i18n.locale = 'ar';
        this.$rtl.enableRTL();
      }

    },
    created() {
      this.getStockValues(this.forceUpdateComponents);
    },
    beforeDestroy() {
      if (this.$rtl.isRTL) {
        this.i18n.locale = 'en';
        this.$rtl.disableRTL();
      }
    },

  };
</script>

<style lang="scss">

.calendar {
  .vfc-today {
    width: 30px;
    line-height: 30px;
    color: #ffffff;
    background-color: #709949;
    border-radius: 100%;
    margin: 0 auto;
  }
}

.green-line {
  width: 20px;
  position: absolute;
  height: 2px;
  background-color: #45cc0d;
  bottom: 3px;
  left: calc(50% - 10px);
}

.green-point {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #45cc0d;
  bottom: 3px;
  left: calc(50% - 4px);
}

.orange-point {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #eb9800;
  bottom: 3px;
  left: calc(50% - 4px);
}
</style>
